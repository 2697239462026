import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SingleImage = makeShortcode("SingleImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "so-what-is-normal"
    }}>{`So, what `}<em parentName="h1">{`is`}</em>{` 'normal'?`}</h1>
    <p>{`The best way to tell whether a change in your teenager’s mood or behaviour is a 'normal' part of adolescence, is to look at its `}<strong parentName="p">{`effects on their functioning`}</strong>{`. For example, if they are having problems with school or work, are cutting themselves off from family and friends, or stop doing things that they used to enjoy, then there is reason for concern.`}</p>
    <p>{`The other thing to look for is whether the change in your teenager’s mood or behaviour has been occurring more frequently or has lasted for more than a couple of weeks.`}</p>
    <p>{`If you’re not sure whether a change in your teenager’s behaviour is due to a mental health problem, you can speak with your GP to help you decide whether your teen may benefit from seeing a mental health professional.`}</p>
    <SingleImage smallGridSize={10} gridSize={10} src="/images/m10/47-01-new.svg" alt="child on stairs" mdxType="SingleImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      